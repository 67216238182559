@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primary {
  background-color: #C01A78;
}

.bg-pink {
  background-color: #FFE7F9;
}

.bg-pink-primary {
  background-color: #E95FAD;
}

.bg-solid-lang {
  background-color: #600D3C;
}

.text-primary {
  color: #C01A78;
}

.text-secondary {
  color: #953106;
}

.text-primary-disabled {
  color: #cf72a6;
}

.h-screen-header {
  min-height: calc(100vh - 80px);
}

.bg-shape-1 {
  background-image: url('./images/home/shape1.png');
  background-repeat: no-repeat;
  background-position: bottom -40px left;
  background-size: 200px;
}

.bg-shape-2 {
  background-image: url('./images/home/shape2.png');
  background-repeat: no-repeat;
  background-position: right -170px bottom -40px;
  background-size: 30%;
}

.bg-shape-quote {
  background-image: url('./images/home/shape_quote1.png'), url('./images/home/shape_quote2.png');
  background-repeat: no-repeat, no-repeat;
  background-position: right -30px top, left -20px bottom;
}

.bg-shape-closer {
  background-image: url('./images/home/shape3.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 200px;
}

.bg-shape-service {
  background-image: url('./images/service/shape.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15%;
}

.bg-merchandise {
  background-image: url('./images/home/ilustrasi_merchandise.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 60%;
}

.bg-about {
  background-image: url('./images/about/shape.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 7%;
}

.img-md {
  width: 32px;
  height: 32px;
}

.border-primary {
  border: 2px solid #C01A78;
}

.border-sm-primary {
  border: 1px solid #C01A78;
}

.border-b-primary {
  border-bottom: 1px solid #C01A78;
}

.border-1-pink {
  border: 2px solid #FFE7F9;
}

.border-b-third {
  border-bottom: 0.5px solid #E95FAD;
}

.container-xl {
  max-width: 1280px;
}

.w-48-percent {
  width: 48%;
}

.services-category {
  background-color: #FFE7F9;
}

.border-left-category {
  border-radius: 9999px;
}

.border-right-category {
  border-radius: 9999px;
}

.w-250 {
  width: 250px;
}

@media (min-width: 200px) and (max-width: 1023px) {
  .scroll-animation {
    animation: scroll 30s linear infinite;
  }
}

@media (min-width: 640px) {
  .sm\:w-500 {
    width: 500px;
  }
}

@media (min-width: 768px) {
  .md\:w-600 {
    width: 600px;
  }
}

@media (min-width: 1024px) {
  .w-34-percent {
    width: 34%;
  }

  .w-42-percent {
    width: 42% !important;
  }

  .services-category {
    background-color: #E95FAD;
  }

  .border-left-category {
    border-radius: 9999px 0px 0px 9999px;
  }

  .border-right-category {
    border-radius: 0px 9999px 9999px 0px;
  }

  .lg\:h-screen-header {
    min-height: calc(100vh - 80px);
  }

  .lg\:w-750 {
    width: 750px;
  }

  .lg\:w-840 {
    width: 840px;
  }

  .lg\:w-880 {
    width: 880px;
  }
}

@media (min-width: 1280px) {
  .xl\:w-800 {
    width: 800px;
  }
}

@media (min-width: 1350px) {
  .xl-2\:max-w-7xl {
    max-width: 1280px !important;
  }

  .xl-2\:max-w-6xl {
    max-width: 1024px !important;
  }
}

.hover\:bg-pink:hover {
  background-color: #FFE7F9;
}

.hover\:text-primary:hover {
  color: #C01A78;
}

 .header-menu div {
  width: 0%;
  height: 1px;
  background-color: #C01A78;
  transition: width 700ms;
}
 
.header-menu:hover div {
  width: 100%;
} 

@keyframes scroll {
  0% {
    transform: translateX(0)
  }
  to {
    transform: translateX(-1900px)
  } 
}